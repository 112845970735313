import domReady from '@wordpress/dom-ready';

// domReady( () => {
// 	const tabButtons = document.querySelectorAll(
// 		'.wp-block-wp-blockency-tab-buttons .wp-block-wp-blockency-tab-button'
// 	);
// 	const tabContents = document.querySelectorAll(
// 		'.wp-block-wp-blockency-tab-contents .wp-block-wp-blockency-tab-content'
// 	);
// 	const tabButtonsArr = Array.from( tabButtons );
// 	const tabContentsArr = Array.from( tabContents );
// 	tabButtonsArr[ 0 ]?.classList.add( 'active' );
// 	tabContentsArr[ 0 ]?.classList.add( 'active' );
// 	// console.log(tabButtonsArr, tabContentsArr);

// 	tabButtonsArr?.forEach( ( button, index ) => {
// 		button.addEventListener( 'click', () => {
// 			tabButtonsArr.forEach( ( ele ) =>
// 				ele.classList.remove( 'active' )
// 			);
// 			tabContentsArr.forEach( ( ele ) =>
// 				ele.classList.remove( 'active' )
// 			);
// 			button.classList.add( 'active' );
// 			tabContentsArr[ index ].classList.add( 'active' );
// 		} );
// 	} );

// 	tabContentsArr?.forEach( ( content, index ) => {
// 		content.addEventListener( 'click', () => {
// 			tabButtonsArr.forEach( ( ele ) =>
// 				ele.classList.remove( 'active' )
// 			);
// 			tabContentsArr.forEach( ( ele ) =>
// 				ele.classList.remove( 'active' )
// 			);
// 			content.classList.add( 'active' );
// 			tabButtonsArr[ index ].classList.add( 'active' );
// 		} );
// 	} );
// } );

// jQuery
domReady( () => {
	( function ( $ ) {
		$( window ).on( 'load', function () {
			const tabButtons = $( '.wp-block-wp-blockency-tab-button' );
			const tabContents = $( '.wp-block-wp-blockency-tab-content' );
			$( tabButtons[ 0 ] ).addClass( 'wp-blockency-tabs-active' );
			$( tabContents[ 0 ] ).addClass( 'wp-blockency-tabs-active' );

			tabButtons.each( function ( index ) {
				$( this ).on( 'click', function () {
					tabButtons.each( function ( i, ele ) {
						$( ele ).removeClass( 'wp-blockency-tabs-active' );
					} );
					tabContents.each( function ( i, ele ) {
						$( ele ).removeClass( 'wp-blockency-tabs-active' );
					} );
					$( this ).addClass( 'wp-blockency-tabs-active' );
					$( tabContents[ index ] ).addClass(
						'wp-blockency-tabs-active'
					);
				} );
			} );
		} );

		// eslint-disable-next-line no-undef
	} )( jQuery );
} );
